import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
//import Commitment from '@widgets/Commitment'
import Basic from '@widgets/Basic'

export default () => (
    <>
      <Seo title='Airdrop' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle header='Airdrop' />
          <Divider />
          <Basic />
        </Main>
        {/*<Sidebar>
          <Commitment />
        </Sidebar>*/}
      </Stack>
    </>
  )